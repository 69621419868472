@import '../fonts/c4c-icon-v1.0/style.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
	padding: 0;
	margin: 0;
	font-weight: 500;
	font-family: var(--montserrat-font);
	overflow-y: overlay;
	scroll-behavior: smooth;
	color: #232323;
	background: #fff;
	width: 100% !important;
}

::-webkit-scrollbar {
	width: 9px;
	height: 9px;
	overflow: visible;
}

::-webkit-scrollbar-thumb {
	background: #f5f5f5;
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	background: transparent;
	margin: 15px 0;
}

@layer base {
	h1 {
		font-style: normal;
		font-size: 30px;
		font-weight: 500;
	}

	h4 {
		/* font-size: 1.5rem; */
		margin-bottom: 1rem;
	}

	.box-shadow {
		box-shadow: 0px 5px 28.5px 1.5px rgb(149 152 200 / 20%) !important;
	}

	.avatar-shadow {
		box-shadow: 0px 5px 28.5px 1.5px rgba(55, 149, 210, 0.2) !important;
	}

	.btn-shadow {
		box-shadow: 0px 5px 15px 0px rgba(149, 152, 200, 0.2);
	}
}

@layer components {
	.center {
		@apply flex items-center justify-center;
	}

	.row {
		@apply flex flex-row items-center;
	}

	.col {
		@apply flex flex-col;
	}

	.row-center {
		@apply flex flex-row items-center justify-center;
	}

	.row-between {
		@apply flex flex-row items-center justify-between;
	}

	.animation {
		@apply transition-all duration-300 ease-linear;
	}
	.btn-default {
		@apply border-[2px] border-default min-w-[16rem] rounded-[8px] py-[8px] px-[20px]  text-default mb-[3rem] font-semibold bg-transparent;
	}

	.btn-default:hover {
		@apply transition-all duration-300 ease-in-out bg-opacity-60;
	}

	.btn-custom {
		@apply text-[15px] py-[10px] px-[35px] rounded-full duration-300 ring-2 bg-white text-[#1a181b] hover:shadow-md;
	}

	.btn-highlight {
		@apply bg-default w-[16rem] rounded-[8px] py-[8px] px-[20px]  text-white mb-[3rem] font-semibold;
	}

	.btn-mini {
		@apply border-[2px] border-default h-[3rem] rounded-[8px] px-[20px]  text-default mb-[3rem] font-semibold;
	}

	.input-default {
		@apply appearance-none block w-full text-[20px] border border-gray-300 rounded-[8px] py-3 px-4 mb-3 h-16 leading-tight;
	}
	.input-default:focus {
		@apply bg-white outline-none border-default;
	}
	.label {
		@apply block tracking-wide mb-[0.7rem] text-[20px] font-medium;
	}
	.error-message {
		@apply float-left italic text-red-400;
	}
	.border-dotted-gray {
		@apply border-t-[1px] border-dashed border-[#a9a9a9];
	}
	.line-gray {
		@apply h-[1px] w-full bg-[#a9a9a9];
	}
	.btn-full {
		@apply;
	}
	.product-banner {
		@apply relative w-full aspect-w-375 aspect-h-281 md:aspect-h-2 md:aspect-w-5;
	}
	.product-background {
		@apply absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-40;
	}
	.product-preview {
		@apply w-full aspect-w-375 md:aspect-w-5 aspect-h-281 md:aspect-h-2 relative mb-[3rem];
	}

	.bg-gradient-default {
		@apply bg-gradient-to-l from-green-1 to-blue-3;
	}

	.bg-gradient-circle {
		@apply bg-gradient-to-t from-green-1/90 to-green-1/70;
	}
}

/* Chrome, Safari, Edge, Opera remove arrow input number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.hide-toolbar {
	display: none !important;
}

.ant-table td {
	white-space: nowrap;
}

#guest pre > .public-DraftStyleDefault-block {
	margin: 0 !important;
	font-size: 12px !important;
	line-height: 25px !important;
}

#guest .public-DraftStyleDefault-ltr {
	line-height: 35px !important;
}
#guest
	.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
	line-height: 35px !important;
}
#guest .rdw-editor-main blockquote {
	padding-left: 20px !important;
}
#guest .rdw-editor-main pre {
	background: transparent !important;
	border-radius: 6px !important;
	padding: 0 !important;
}

pre code.hljs {
	padding: 10px !important;
	white-space: pre-wrap !important;
	border-radius: 6px !important;
}

.rdw-link-decorator-wrapper a {
	color: #3074b4;
}
