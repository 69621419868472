@font-face {
  font-family: 'c4c-icon';
  src:  url('fonts/c4c-icon.eot?yyr55j');
  src:  url('fonts/c4c-icon.eot?yyr55j#iefix') format('embedded-opentype'),
    url('fonts/c4c-icon.ttf?yyr55j') format('truetype'),
    url('fonts/c4c-icon.woff?yyr55j') format('woff'),
    url('fonts/c4c-icon.svg?yyr55j#c4c-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="c4c-"], [class*=" c4c-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'c4c-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.c4c-facebook:before {
  content: "\e900";
}
.c4c-instagram:before {
  content: "\e901";
}
.c4c-linkdln:before {
  content: "\e902";
}
.c4c-youtube:before {
  content: "\e903";
}
.c4c-box:before {
  content: "\e904";
}
.c4c-person:before {
  content: "\e905";
}
.c4c-phone:before {
  content: "\e906";
}
.c4c-react-native:before {
  content: "\e907";
}
.c4c-service:before {
  content: "\e908";
}
.c4c-support:before {
  content: "\e909";
}
